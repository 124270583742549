import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const HomeContent = () => {
  return (
    <>
      <div className="content pt text-white">
        <div>
          <figure class="mb-3 mb-lg-4">
            <StaticImage
              src="../../images/hero-banner-es.jpg"
              alt="Hero Banner"
              quality={100}
            />
          </figure>
        </div>
        <div className="page-heading">
          <h1 className="text-uppercase">
            Cubre tus errores en el juego con Snickers
          </h1>
        </div>
        <p className="text-md mb-0">
          Si cometiste un error mientras jugabas porque tenías hambre, Snickers
          te tiene cubierto. Con nuestro innovador Seguro Contra Hambre, podrás
          cambiar ese oso que hiciste jugando por un delicioso Snickers.
        </p>
        <p className="text-md mb-0">
          Busca la póliza que mejor se ajuste a tu situación o habla con uno de
          nuestros agentes para ver si tu accidente por hambre está cubierto.
          ¡Nuestros agentes están listos para ayudarte!
        </p>
      </div>
    </>
  );
};

export default HomeContent;
