import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import Cookies from "universal-cookie"

import Header from "../header/headerEs"
import Footer from "../footer/footerEs"
import HomeContent from "../home-content/homeContentEs"
import HomeTabs from "../home-tabs/homeTabsEs"
import Claim from "../claim/claimEs"
import ChatModal from "../Modals/chat-modalEs"

const Landing = () => {
  let ageCookie = new Cookies()
  const [popUp, setPopUp] = useState(false)
  useEffect(() => {
    if (!ageCookie.get("isOldEnough")) {
      setPopUp(false)
      navigate("/es/agegate")
    } else {
      setPopUp(true)
    }
  }, [])
  return (
    <>
      <Header />
      <section className="main-section bg-primary mb-4 mb-md-5">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-lg-6">
              <HomeContent />
            </div>
            <div className="col-lg-6 bg-secondary position-relative px-0 main-tabs">
              <HomeTabs />
            </div>
          </div>
        </div>
      </section>

      <Claim />
      <div className="mb-4">
        <Footer />
      </div>

      {/* <df-messenger
          intent="WELCOME"
          chat-title="SnickersInsuranceChatbot"
          agent-id="b82be01c-2c46-40b8-b515-b428454db79a"
          language-code="en"
        ></df-messenger> */}

      <ChatModal show={popUp} onHide={() => setPopUp(false)} animation={true} />
    </>
  )
}

export default Landing
