import React from "react";

import { Modal } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import Claim from "../ModalClaim/claimEs";
import oxxo from "../../images/es/logo-oxxo-mr.png";

function ChatModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="align-items-start">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="d-flex align-items-center"
        >
          <figure className="ps-md-4 ps-2 mb-0">
            <StaticImage
              className="modal-logo"
              src="../../images/snickers-hunger-insurance-es.png"
              alt="Snickers Logo"
              quality={100}
            />
          </figure>
          <figure className="modal-fig-profile mb-0 position-absolute">
            <StaticImage
              className="modal-profile"
              src="../../images/es-profile-photo.png"
              alt="Profile Photo"
              quality={100}
            />
          </figure>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h1 className="modal-text text-center text-uppercase text-white mt-3 mb-0">
          Comienza una reclamación
        </h1>
        <div className="d-flex align-items-center justify-content-center">
          <h2 className="es modal-text text-center text-uppercase text-white m-0">
            RECIBE UN CUPÓN EN:
          </h2>
          <img className="oxxo-logo" src={oxxo} alt="OXXO Logo" />
          {/* <StaticImage
            className="img-fluid"
            src="../../images/es/logo-oxxo-mr.png"
            alt="OXXO logo"
            quality={100}
          /> */}
        </div>
        <div className="d-flex justify-content-center py-md-4 py-3">
          <Claim />
        </div>
      </Modal.Body>
      {/* <Modal.Footer className="justify-content-center pt-0 pb-md-4 pb-3">
        <Claim />
      </Modal.Footer> */}
    </Modal>
  );
}

export default ChatModal;
