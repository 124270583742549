import React from "react";
import ChatButton from "../chatBtnEs";
import iconRageQuit from "../../../images/es/icon-rage-quit.png";

const RageQuitTab = () => {
  return (
    <>
      <div
        className="tab-pane fade"
        id="rage-quit"
        role="tabpanel"
        aria-labelledby="rage-quit-tab"
      >
        <div className="d-flex flex-column justify-content-center h-100">
          <figure className="mb-0 icon-main">
            {/* <StaticImage
              src="../../../images/icon-rage-quit.png"
              alt="Rage Quit"
              quality={100}
            /> */}
            <img src={iconRageQuit} alt="Rage Quit" className="img-fluid" />
          </figure>
          {/* <h3 className="text-uppercase">Rage Quit</h3> */}
          <p>
            ¿Le pegaste a algo, o a alguien, en un arranque incontrolable de
            rabia por jugar horrible? No cubrimos controles rotos ni daños
            físicos, pero podemos ayudarte a prevenir esa ardidez que te hace
            quitar el juego, causada por el hambre. ¿Y tal vez recomendarte un
            curso para el control de la ira?
          </p>
          <div className="text-center">
            <span className="text-uppercase">
              <ChatButton innerText="Chatea con uno de nuestros agentes ahora" />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default RageQuitTab;
