import React from "react";
import ChatButton from "../chatBtnEs";
import iconSaveSnafu from "../../../images/es/icon-save-snafu.png";

const SaveSnafuTab = () => {
  return (
    <>
      <div
        className="tab-pane fade"
        id="save-snafu"
        role="tabpanel"
        aria-labelledby="save-snafu-tab"
      >
        <div className="d-flex flex-column justify-content-center h-100 py-md-4">
          <figure className="mb-0 icon-main">
            {/* <StaticImage
              src="../../../images/icon-save-snafu.png"
              alt="Save Snafu"
              quality={100}
            /> */}
            <img src={iconSaveSnafu} alt="Save Snafu" className="img-fluid" />
          </figure>
          {/* <h3 className="text-uppercase">Save Snafu</h3> */}
          <p>
            ¿Sin querer borraste tu progreso de días dándole restart en el peor
            momento posible? ¿O por confiarle tu partida a tu hermanito y luego
            él se desquitó contigo? ¿O tal vez simplemente tenías hambre y no
            guardaste antes de hacer algo supremamente tonto? Te tenemos
            cubierto. Y aunque no podemos ayudarte a recuperar esa partida
            perdida, podemos darte algo delicioso para secar tus lágrimitas: un
            Snickers.
          </p>
          <div className="text-center">
            <span className="text-uppercase">
              <ChatButton innerText="Chatea con uno de nuestros agentes ahora" />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SaveSnafuTab;
