import React from "react"

import TabLinks from "./tabLinksEs"
import NoobMoveTab from "./tab-content-es/noobMoveTab"
import EarlyDeathTab from "./tab-content-es/earlyDeathTab"
import RageQuitTab from "./tab-content-es/rageQuitTab"
import SaveSnafuTab from "./tab-content-es/saveSnafuTab"
import LootLossTab from "./tab-content-es/lootLoss"
import OtherBlundersTab from "./tab-content-es/otherBlunders"

const HomeTabs = () => {
  return (
    <>
      <div className="text-center nav-tabs-header es">
        <h2 className="text-uppercase">Accidentes que cubrimos:</h2>
        <TabLinks />
      </div>
      <div className="tab-content text-center text-white" id="myTabContent">
        <NoobMoveTab />
        <EarlyDeathTab />
        <RageQuitTab />
        <SaveSnafuTab />
        <LootLossTab />
        <OtherBlundersTab />
      </div>
    </>
  )
}

export default HomeTabs
