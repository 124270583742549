import React from "react";
import ChatButton from "../chatBtnEs";
import iconNoobMove from "../../../images/es/icon-noob-move.png";

const NoobMoveTab = () => {
  return (
    <>
      <div
        className="tab-pane fade show active"
        id="noob-move"
        role="tabpanel"
        aria-labelledby="noob-move-tab"
      >
        <div className="d-flex flex-column justify-content-center h-100">
          <figure className="mb-0 icon-main">
            {/* <StaticImage
              src="../../../images/icon-noob-move.png"
              alt="Noob Move"
              quality={100}
            /> */}
            <img src={iconNoobMove} alt="Noob Move" className="img-fluid" />
          </figure>
          {/* <h3 className="text-uppercase">Noob Move</h3> */}
          <p>
            ¿Haz hecho cosas tan torpes que hasta al noob más noob del mundo le
            diste pena ajena? Te tenemos cubierto. Nuestros agentes están listos
            ahora mismo para ayudarte con tu reclamación sin juzgarte ni un
            poco.
          </p>
          <div className="text-center">
            <span className="text-uppercase">
              <ChatButton innerText="Chatea con uno de nuestros agentes ahora" />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoobMoveTab;
